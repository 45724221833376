<!-- 
 * @Description: 客服用户服务管理 申诉管理 订单申诉管理 orderComplainManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 11:43:05
 * @LastEditors: houjinduo houjinduo1007@163.com
-->
<template>
  <div class="mainBody">
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="订单编号">
              <el-input v-model="searchForm.orderId"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="申诉类型">
              <el-select v-model="searchForm.appealType"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in complaintTypeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="手机号码">
              <el-input v-model="searchForm.appealPhone"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="创建时间">
              <el-date-picker v-model="Time"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="handleClickQuery">查 询</el-button>

          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>订单申诉管理</span>
         <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="申诉状态">
              <template slot-scope="scope">
                <span v-if="scope.row.dealState === '待处理'"
                      style="color:#F63B24 ">{{scope.row.dealState}}</span>
                <span v-if="scope.row.dealState === '已处理'"
                      style="color:#00D03F">{{scope.row.dealState}}</span>
                <span v-if="scope.row.dealState === '已驳回'"
                      style="color:#3C6A8B">{{scope.row.dealState}}</span>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="12">
                  <el-button type="text"
                             size="medium"
                             @click="handleClickDetails(scope.row)">查看详情</el-button>
                </el-col>
                <el-col :span="12">
                  <el-button type="text"
                             size="medium"
                             v-if="scope.row.dealState==='待处理'"
                             @click="handelClickDispose(scope.row)">处理</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <el-dialog title="申诉详情"
               :visible.sync="viewDialogVisible"
               append-to-body
               :close-on-click-modal="false"
               @close="getComplaintList">
      <el-row class="dialogLine">
        <el-row class="dialogLineOne">
          <el-row class="dialogLineOne-left">
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单编号:</el-row>
              <el-row class="lineText">{{viewComplainList.orderId?viewComplainList.orderId:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-lefttwo">
              <el-row class="lineTitle">手机号码:</el-row>
              <el-row class="lineText">
                {{viewComplainList.appealPhone?viewComplainList.appealPhone:""}}
                <!-- {{viewComplainList.leaveTime?viewComplainList.leaveTime:""}} -->
              </el-row>
              <el-row class="lineTitle">申诉类型:</el-row>
              <el-row class="lineText">{{viewComplainList.appealTypeName?viewComplainList.appealTypeName:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-lefttwo">
              <el-row class="lineTitle">申诉时间:</el-row>
              <el-row class="lineText">
                {{viewComplainList.appealTime?viewComplainList.appealTime:""}}
                <!-- {{viewComplainList.leaveTime?viewComplainList.leaveTime:""}} -->
              </el-row>
              <el-row class="lineTitle">申诉类型:</el-row>
              <el-row class="lineText">{{viewComplainList.appealTypeName?viewComplainList.appealTypeName:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftthree">
              <el-row class="lineTitle">申诉描述:</el-row>
              <el-row class="lineText">{{viewComplainList.appealReason?viewComplainList.appealReason:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">车牌号:</el-row>
              <el-row class="lineText">
                {{viewComplainList.plateNumber?viewComplainList.plateNumber:""}}
                <!-- （{{viewComplainList.numberPlateColorCodeName?viewComplainList.numberPlateColorCodeName:""}}） -->
              </el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">计费时长:</el-row>
              <el-row class="lineText">{{viewComplainList.billingDurationCalc?viewComplainList.billingDurationCalc:0}}分钟</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单应收金额:</el-row>
              <el-row class="lineText"
                      style="color:#C09200">{{viewComplainList.receivableMoneyAmount?Number(viewComplainList.receivableMoneyAmount).toFixed(2):0}} 元</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单实收金额:</el-row>
              <el-row class="lineText"
                      style="color:#C09200">{{viewComplainList.receivedMoneyAmount?Number(viewComplainList.receivedMoneyAmount).toFixed(2):0}} 元</el-row>
            </el-row>
          </el-row>
          <el-row class="dialogLineOne-mid">
            <el-row class="lineTitleIn">{{viewComplainList.inevidence?viewComplainList.inevidence.eventTypeDesc:""}}</el-row>
            <el-row class="lineText">{{viewComplainList.inevidence?viewComplainList.inevidence.evidenceCreateTime:""}}</el-row>
            <el-row class="lineImg">
              <img v-if="viewComplainList.inevidencePic"
                   :src="$imgBaseUrl + viewComplainList.inevidencePic"
                   alt="">
              <el-row v-else
                      class="lineImgText">暂无取证图片</el-row>
            </el-row>
          </el-row>
          <el-row class="dialogLineOne-right">
            <el-row class="lineTitleOut">{{viewComplainList.outevidence?viewComplainList.outevidence.eventTypeDesc:""}}</el-row>
            <el-row class="lineText">{{viewComplainList.outevidence?viewComplainList.outevidence.evidenceCreateTime:""}}</el-row>
            <el-row class="lineImg">
              <img v-if="viewComplainList.outevidencePic"
                   :src="$imgBaseUrl + viewComplainList.outevidencePic"
                   alt="">
              <el-row v-else
                      class="lineImgText">暂无取证图片</el-row>
            </el-row>
          </el-row>
        </el-row>
        <el-row class="lineButton">
          <el-button type="primary"
                     @click="viewDialogVisible=false">取消</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <el-dialog title="处理申诉"
               :visible.sync="handleDialogVisible"
               append-to-body
               :close-on-click-modal="false"
               @close="getComplaintList">
      <el-row class="dialogLine">
        <el-row class="dialogLineOne">
          <el-row class="dialogLineOne-left">
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单编号:</el-row>
              <el-row class="lineText">{{viewComplainList.orderId?viewComplainList.orderId:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-lefttwo">
              <el-row class="lineTitle">手机号码:</el-row>
              <el-row class="lineText">
                {{viewComplainList.appealPhone?viewComplainList.appealPhone:""}}
              </el-row>
              <el-row class="lineTitle">申诉类型:</el-row>
              <el-row class="lineText">{{viewComplainList.appealTypeName?viewComplainList.appealTypeName:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-lefttwo">
              <el-row class="lineTitle">申诉时间:</el-row>
              <el-row class="lineText">
                {{viewComplainList.appealTime?viewComplainList.appealTime:""}}
              </el-row>
              <el-row class="lineTitle">申诉类型:</el-row>
              <el-row class="lineText">{{viewComplainList.appealTypeName?viewComplainList.appealTypeName:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftthree">
              <el-row class="lineTitle">申诉描述:</el-row>
              <el-row class="lineText">{{viewComplainList.appealReason?viewComplainList.appealReason:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">车牌号:</el-row>
              <el-row class="lineText">
                {{viewComplainList.plateNumber?viewComplainList.plateNumber:""}}
                <!-- （{{viewComplainList.numberPlateColorCodeName?viewComplainList.numberPlateColorCodeName:""}}） -->
              </el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">计费时长:</el-row>
              <el-row class="lineText">{{viewComplainList.billingDurationCalc?viewComplainList.billingDurationCalc:0}}分钟</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单应收金额:</el-row>
              <el-row class="lineText"
                      style="color:#C09200">{{viewComplainList.receivableMoneyAmount?Number(viewComplainList.receivableMoneyAmount).toFixed(2):0}} 元</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单实收金额:</el-row>
              <el-row class="lineText"
                      style="color:#C09200">{{viewComplainList.receivedMoneyAmount?Number(viewComplainList.receivedMoneyAmount).toFixed(2):0}} 元</el-row>
            </el-row>
          </el-row>
          <el-row class="dialogLineOne-mid">
            <el-row class="lineTitleIn">{{viewComplainList.inevidence?viewComplainList.inevidence.eventTypeDesc:""}}</el-row>
            <el-row class="lineText">{{viewComplainList.inevidence?viewComplainList.inevidence.evidenceCreateTime:""}}</el-row>
            <el-row class="lineImg">
              <img v-if="viewComplainList.inevidencePic"
                   :src="$imgBaseUrl + viewComplainList.inevidencePic"
                   alt="">
              <el-row v-else
                      class="lineImgText">暂无取证图片</el-row>
            </el-row>
          </el-row>
          <el-row class="dialogLineOne-right">
            <el-row class="lineTitleOut">{{viewComplainList.outevidence?viewComplainList.outevidence.eventTypeDesc:""}}</el-row>
            <el-row class="lineText">{{viewComplainList.outevidence?viewComplainList.outevidence.evidenceCreateTime:""}}</el-row>
            <el-row class="lineImg">
              <img v-if="viewComplainList.outevidencePic"
                   :src="$imgBaseUrl + viewComplainList.outevidencePic"
                   alt="">
              <el-row v-else
                      class="lineImgText">暂无取证图片</el-row>
            </el-row>
          </el-row>
        </el-row>
        <el-row class="dialogLineTwo"></el-row>
        <el-row class="dialogLineThree">
          <el-form :model="popupForm">
            <el-row class="lineOne">
              <el-form-item label-width="120px"
                            label="处理备注:">
                <el-input type="textarea"
                          :rows="2"
                          style="width"
                          placeholder="请输入内容"
                          v-model="popupForm.dealBak">
                </el-input>
              </el-form-item>
            </el-row>
            <el-row class="lineTwo">
              <el-form-item label-width="120px"
                            label="工单上报原因:">
                <el-input type="textarea"
                          :rows="2"
                          placeholder="请输入内容"
                          v-model="popupForm.workOrderReportReason">
                </el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </el-row>
        <el-row class="lineButton">
          <el-button type="info"
                     @click="handleClickAccept()">生成工单</el-button>
          <el-button type="primary"
                     @click="handleClickReject()">驳回</el-button>
          <el-button type="primary"
                     @click="handleDialogVisible=false">取消</el-button>
        </el-row>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>  
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import $axios from "axios";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      
      tableColumnList: [
        {
          prop: 'orderId',
          label: '订单编号',
        },
        {
          prop: 'appealPhone',
          label: '手机号码',
        },
        {
          prop: 'appealType',
          label: '申诉类型',
        },
        {
          prop: 'appealReason',
          label: '申诉描述',
        },
        {
          prop: 'appealTime',
          label: '申诉时间',
        },

      ],
      tableList: {
        list: []
      },
      // 查询表单
      searchForm: {
        orderId: "",
        appealType: "",
        appealPhone: "",
        startTime: "",
        endTime: ""
      },
      Time: [], //顶部时间选择
      pageNum: 1,
      pageSize: 15,
      viewDialogVisible: false, // 查看弹窗详情
      viewComplainList: [],// 查看弹窗列表
      id: '',//驳回处理id存储
      handleDialogVisible: false,//处理弹窗
      popupForm: {},//查看弹窗详情
      complaintTypeList: [], // 申诉类型下拉框
      complaintStateList: [], // 申诉状态下拉框
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryDictList()
  },
  //方法集合
  methods: {
    // 导出
    exportFile () {
      const loading = this.$loading({
        target: '#monitorBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$axios.post(this.$downloadOrderBaseUrl + "OrderAppeal/exportIn", this.searchForm , {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }
      ).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "订单申诉表.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
        loading.close()
        
      })
    },
    // 生成工单
    handleClickAccept () {
      console.log(this.id);
      let info = {
        id: this.id,
        dealBak: this.popupForm.dealBak,
        workOrderReportReason: this.popupForm.workOrderReportReason,
      }
      this.$orderComplainManage.acceptOrderAppeal(info).then(() => {
        this.$message({ message: '工单上报成功！', type: 'success' });
        this.handleDialogVisible = false
        this.getComplaintList()
      }).catch(err => {
        this.$message({ message: err.resultMsg, type: 'error' });
      })
    },
    // 驳回工单
    handleClickReject () {
      console.log(this.viewComplainList.id);
      let info = {
        id: this.id,
        dealBak: this.popupForm.dealBak
      }
      this.$orderComplainManage.rejectOrderAppeal(info).then(() => {
        this.$message({ message: '驳回成功！', type: 'success' });
        this.handleDialogVisible = false
        this.getComplaintList()
      }).catch(err => {
        this.$message({ message: err.resultMsg, type: 'error' });
      })
    },
    // 点击查看详情
    handleClickDetails (row) {
      this.viewDialogVisible = true
      let info = {
        id: row.id
      }
      this.$orderComplainManage.getInfoById(info).then(res => {
        this.viewComplainList = res.resultEntity
        console.log(this.viewComplainList);
        this.viewComplainList.inevidence = res.resultEntity.orderEvidence[0]
        this.viewComplainList.outevidence = res.resultEntity.orderEvidence[1]
        if (res.resultEntity.orderEvidence[0].evidencePic) {
          this.viewComplainList.inevidencePic = res.resultEntity.orderEvidence[0].evidencePic
        }
        if (res.resultEntity.orderEvidence[1].evidencePic) {
          this.viewComplainList.outevidencePic = res.resultEntity.orderEvidence[1].evidencePic
        }
      })
    },
    // 处理
    handelClickDispose (row) {
      this.handleDialogVisible = true
      let info = {
        id: row.id
      }
      this.id = row.id
      this.$orderComplainManage.getInfoById(info).then(res => {
        this.viewComplainList = res.resultEntity
        this.viewComplainList.inevidence = res.resultEntity.orderEvidence[0]
        this.viewComplainList.outevidence = res.resultEntity.orderEvidence[1]
        if (res.resultEntity.orderEvidence[0].evidencePic) {
          this.viewComplainList.inevidencePic = res.resultEntity.orderEvidence[0].evidencePic
        }
        if (res.resultEntity.orderEvidence[1].evidencePic) {
          this.viewComplainList.outevidencePic = res.resultEntity.orderEvidence[1].evidencePic
        }
      })
    },
    // 申诉类型 申诉状态
    queryDictList () {
      let info1 = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [{
          colName: 'parent_id',
          value: 'F8898BDD4BCF4W12ABA91DBUIUIUIU',
        },],
      }
      let info2 = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [{
          colName: 'parent_id',
          value: 'F8898BDD4BCF4W12ABA91DB0DEF',
        },],
      }
      $axios.all([this.$queryDict.queryDict(info1), this.$queryDict.queryDict(info2)]).then(([res1, res2]) => {
        this.complaintTypeList = res1.resultEntity
        this.complaintStateList = res2.resultEntity
        this.getComplaintList()
      })
    },
    // 订单申诉列表
    getComplaintList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$orderComplainManage.getOrderAppealList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.tableList.list.forEach(Item => {
          // 申诉类型
          this.complaintTypeList.forEach(item => {
            if (Item.appealType === item.code) {
              Item.appealType = item.name
            }
          });
          // 申诉状态
          this.complaintStateList.forEach(item => {
            if (Item.dealState == item.code) {
              Item.dealState = item.name
            }
          });
        });
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.getComplaintList()
    },
    // 点击查询
    handleClickQuery () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.Time) {
        this.searchForm.startTime = this.Time[0]
        this.searchForm.endTime = this.Time[1]
      } else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.getComplaintList()
    },
    //重置
    resetForm () {
      this.Time = []
      this.searchForm = {}
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.getComplaintList()
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
// 最外层div
.mainBody {
  width: 100%;
  overflow-x: hidden;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      margin-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dialogLine {
  .dialogLineOne {
    width: 100%;
    height: 260px;
    display: flex;
    .dialogLineOne-left {
      width: 60%;
      height: 260px;
      .dialogLineOne-leftone {
        width: 80%;
        margin-top: 10px;
        display: flex;
        .lineTitle {
          width: 25%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #70aee8;
        }
        .lineText {
          width: 75%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #bae8ff;
        }
      }
      .dialogLineOne-lefttwo {
        width: 100%;
        margin-top: 10px;
        display: flex;
        .lineTitle {
          width: 40%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #70aee8;
        }
        .lineText {
          width: 60%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #bae8ff;
        }
      }
      .dialogLineOne-leftthree {
        width: 100%;
        height: 35px;
        margin-top: 10px;
        display: flex;
        .lineTitle {
          width: 20%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #70aee8;
        }
        .lineText {
          width: 80%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #bae8ff;
        }
      }
    }
    .dialogLineOne-mid {
      width: 18%;
      height: 240px;
      margin-top: 10px;
      margin-left: 2%;
      border: 1px solid#0F8AD4;
      .lineTitleIn {
        width: 90%;
        height: 10%;
        line-height: 30px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #6de721;
        margin-left: 10%;
      }
      .lineText {
        width: 90%;
        height: 10%;
        line-height: 30px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #ffffff;
        margin-left: 10%;
      }
      .lineImg {
        width: 100%;
        height: 80%;
        img {
          width: 100%;
          height: 100%;
        }
        .lineImgText {
          text-align: center;
          color: #0f8ad4;
        }
      }
    }
    .dialogLineOne-right {
      width: 18%;
      height: 240px;
      margin-top: 10px;
      margin-left: 2%;
      border: 1px solid#0F8AD4;
      .lineTitleOut {
        width: 90%;
        height: 10%;
        line-height: 30px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #ed740e;
        margin-left: 10%;
      }
      .lineText {
        width: 90%;
        height: 10%;
        line-height: 30px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #ffffff;
        margin-left: 10%;
      }
      .lineImg {
        width: 100%;
        height: 80%;
        img {
          width: 100%;
          height: 100%;
        }
        .lineImgText {
          text-align: center;
          color: #0f8ad4;
        }
      }
    }
  }
  .dialogLineTwo {
    width: 100%;
    border: 1px solid #004474;
    margin-top: 10px;
  }
  .dialogLineThree {
    .lineOne {
      margin-top: 20px;
      /deep/.el-textarea {
        width: 800px;
      }
    }
    .lineTwo {
      margin-top: 20px;
      /deep/.el-textarea {
        width: 800px;
      }
    }
  }
  .lineButton {
    display: flex;
    margin-top: 20px;
    float: right;
  }
}
</style>